import React, { useState, useEffect } from "react"
import useTranslations from "../../utils/useTranslations"
import { FaAndroid } from 'react-icons/fa';
import Logo from "../../components/logo"
import LocalizedLink from "../../components/localizedLink"
import arrowBlack from "../../images/icons/arrow-black.svg"
import { ANDROID_LINK_FREE, ANDROID_LINK_PRO, getAndroidLink } from "../../utils/getAndroidLink";


const PlatformsAndroid = ({ pageContext: {text, locale} }) => {
	const t = useTranslations(text)
	const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

	const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)
 	const [androidLinkPro, setAndroidLinkPro] = useState(ANDROID_LINK_PRO)

 	useEffect(()=>{
		setAndroidLinkFree(getAndroidLink('free'))
		setAndroidLinkPro(getAndroidLink('pro'))
  	},[])


  	return (
		<div className="container my-5 pt-5">
			<div className="row mb-5">
				<div className="col-12 text-center text-md-left">
					<h1><FaAndroid className="mb-2" />  <span className="ml-2 d-inline-block">{t("BitTorrent Products for Android")}</span></h1>
				</div>
			</div>

			<div className="row">
				<div className="col-12 text-center text-md-left">
					<p className="platforms-section-description">
						{t(`BitTorrent offers torrent downloaders for Android that are fast and easy to use. Available on Google Play, our Android torrent clients enable you to download at high speeds, remotely access your desktop app, or use BitTorrent Android to view files with our integrated music and video player.`)}
					</p>
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-12 text-center text-md-left">


					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Android" className="text-center text-md-left mb-2 mb-md-0" fsMax={22}/>
	              		<div className="flex-shrink-0 align-items-center align-middle d-flex flex-column flex-md-row">
		              		<p className="d-inline-block text-size-32 my-auto mr-md-3">{t(`FREE`)} </p>
		              		<a href={androidLinkFree} id="android-platforms-free" className="index-mobile-google-play flex-shrink-0" target="_blank" rel="noopener noreferrer">
	                			<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
	              			</a>
		              	</div>
					</div>

					<hr className="platforms-hr-border"/>
					<p className="platforms-section-description">
						{t(`Get the most trusted Android torrent client in the Google Play Store. Download torrent files and magnet links on your mobile device at high speeds, playback files, use Wifi-only mode to save your data plan, remotely access BitTorrent Classic for Windows on your home computer, and more.`)}
					</p>

					<LocalizedLink
						id="platforms-android-free-learnmore" 
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/android/bittorrent-android-free">

						{t(`Learn more`)} 

					</LocalizedLink>
					<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>

				</div>
			</div>

			<div className="row pb-5 my-5">
				<div className="col-12 text-center text-md-left">


					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Android Pro" className="text-center text-md-left mb-2 mb-md-0" fsMax={22}/>
		              	<div className="flex-shrink-0 align-items-center align-middle d-flex flex-column flex-md-row">
		              		<p className="d-inline-block text-size-32 my-auto mr-md-3">{t(`$3.99`)} </p>
		              		<a href={androidLinkPro} id="android-platforms-pro" className="index-mobile-google-play flex-shrink-0" target="_blank" rel="noopener noreferrer">
	                			<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
	              			</a>
		              	</div>
					</div>

					<hr className="platforms-hr-border"/>
					<p className="platforms-section-description">
						{t(`Get more out of the #1 torrent downloader for Android. Go ad-free, suspend torrents when your battery drops below a predefined level, and save data by stopping torrents when your app is in the background and your downloads are finished.`)}
					</p>

					<LocalizedLink
						id="platforms-android-pro-learnmore" 
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
						to="/products/android/bittorrent-android-pro">

						{t(`Learn more`)} 

					</LocalizedLink>
					<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>

				</div>
			</div>
			
		</div>
 	)
}

export default PlatformsAndroid